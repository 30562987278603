export const ScoutStickSvg = () => {
  return (
    <svg
      id='Scout_Button_-_Main'
      data-name='Scout Button - Main'
      xmlns='http://www.w3.org/2000/svg'
      width='456'
      height='242'
      viewBox='0 0 456 242'
    >
      <defs>
        <filter
          id='shadow'
          x='61'
          y='103'
          width='358'
          height='130'
          filterUnits='userSpaceOnUse'
        >
          <feOffset dx='6' dy='12' input='SourceAlpha' />
          <feGaussianBlur stdDeviation='6' result='blur' />
          <feFlood floodOpacity='0.329' />
          <feComposite operator='in' in2='blur' />
          <feComposite in='SourceGraphic' />
        </filter>
        <clipPath id='clipPath'>
          <rect
            id='Rectangle_99'
            data-name='Rectangle 99'
            width='437'
            height='208'
            transform='translate(407.887 571.119)'
            fill='none'
          />
        </clipPath>
      </defs>
      <g transform='matrix(1, 0, 0, 1, 0, 0)' filter='url(#shadow)'>
        <path
          id='shadow-2'
          data-name='shadow'
          d='M10.062,0H311.937A9.93,9.93,0,0,1,322,9.792V84.208A9.93,9.93,0,0,1,311.937,94H10.062A9.93,9.93,0,0,1,0,84.208V9.792A9.93,9.93,0,0,1,10.062,0Z'
          transform='translate(73 109)'
          fill='#fff'
        />
      </g>
      <g id='Icon' transform='translate(94 116)'>
        <g id='Layer_1' data-name='Layer 1' transform='translate(0 0)'>
          <g id='Group_18' data-name='Group 18' transform='translate(82.453 0)'>
            <path
              id='Path_7'
              data-name='Path 7'
              d='M299.621,60.038l-8.133-1.868a3.2,3.2,0,0,0-2.958.838h0a5.34,5.34,0,0,1-.921-2.958V52.364a19.013,19.013,0,0,0,3.46-5.54c2.154,1.432,4.018-3.107,4.018-5.088a2,2,0,0,0-2.227-2.23q.09-1.037.09-2.044c0-8.555-6.693-11.752-14.956-11.752s-14.956,3.2-14.956,11.752c0,.665.033,1.366.093,2.061-1.359-.16-2.23.685-2.23,2.213,0,2.064,1.854,6.906,3.932,4.876a19.134,19.134,0,0,0,3.546,5.753V56.05a5.342,5.342,0,0,1-.9,2.965h0a3.194,3.194,0,0,0-2.975-.834L256.326,60.1a10.682,10.682,0,0,0-8.236,10.4v9.7a3.207,3.207,0,0,0,3.207,3.207h53.412a3.2,3.2,0,0,0,3.2-3.207V70.434A10.678,10.678,0,0,0,299.621,60.038Z'
              transform='translate(-248.09 -17.165)'
              fill='#988567'
            />
            <path
              id='Path_8'
              data-name='Path 8'
              d='M330.287,115.937a5.341,5.341,0,0,1-1.9-4.078V109.3H309.16c1,2.895,7.833,9.206,14.018,11.812a25.009,25.009,0,0,0,7.109-5.175Z'
              transform='translate(-288.863 -72.974)'
              fill='#8c7655'
            />
            <path
              id='Path_9'
              data-name='Path 9'
              d='M288.53,124.038c-1.589,1.565-3.091,2.9-4.44,4.011a9.565,9.565,0,0,1-12.177,0c-1.329-1.11-2.845-2.443-4.43-3.988a3.194,3.194,0,0,0-2.975-.834l-8.182,1.924a10.682,10.682,0,0,0-8.236,10.4v9.7a3.207,3.207,0,0,0,3.207,3.207h53.412a3.2,3.2,0,0,0,3.2-3.207v-9.771a10.678,10.678,0,0,0-8.292-10.4l-8.133-1.868a3.2,3.2,0,0,0-2.958.824Z'
              transform='translate(-248.09 -82.208)'
              fill='#006b77'
            />
            <path
              id='Path_10'
              data-name='Path 10'
              d='M300.984,170.889a6.145,6.145,0,0,1-2.961-.741l-3.719-2.057a6.182,6.182,0,0,0-2.961-.741H264.678a6.2,6.2,0,0,0-2.965.741L258,170.148a6.157,6.157,0,0,1-2.964.741H248.09v4.427a2.971,2.971,0,0,0,3.207,2.659h53.412a2.968,2.968,0,0,0,3.2-2.659v-4.427Z'
              transform='translate(-248.09 -111.731)'
              fill='#006b77'
            />
            <path
              id='Path_11'
              data-name='Path 11'
              d='M318.619,39.506q.09-1.037.09-2.044c0-8.555-6.693-11.752-14.956-11.752S288.8,28.907,288.8,37.462c0,.665.033,1.366.093,2.061-1.359-.16-2.23.685-2.23,2.213,0,2.064,1.854,6.906,3.932,4.876,2.247,5.534,6.617,10.08,13.161,10.08,6.461,0,10.8-4.43,13.075-9.867,2.154,1.432,4.018-3.107,4.018-5.088A2,2,0,0,0,318.619,39.506Z'
              transform='translate(-273.841 -17.165)'
              fill='#94846b'
            />
            <path
              id='Path_12'
              data-name='Path 12'
              d='M305.9,79.223c-10.931,0-16.023-12.749-16.023-21.363h2.137c0,7.754,4.414,19.226,13.886,19.226s13.889-11.473,13.889-19.226h2.137C321.929,66.475,316.84,79.223,305.9,79.223Z'
              transform='translate(-275.991 -38.63)'
              fill='#231f20'
            />
            <path
              id='Path_13'
              data-name='Path 13'
              d='M313.363,1.329a.954.954,0,0,0-.5-.14h-1.426a1.024,1.024,0,0,0-.758.349h0a1,1,0,0,1-1.509,0l-.758-.844A2.027,2.027,0,0,0,306.9,0h-2.5a2.034,2.034,0,0,0-1.512.695l-.754.841a1,1,0,0,1-1.512,0h0a1.017,1.017,0,0,0-.754-.349h-1.429a.971.971,0,0,0-.5.14c-1.177.708-6.418,4.1-8.435,9.356h32.3C319.78,5.427,314.539,2.034,313.363,1.329Z'
              transform='translate(-275.737 0)'
              fill='#231f20'
            />
            <path
              id='Path_14'
              data-name='Path 14'
              d='M286.66,54.64l.807,5.65a2.134,2.134,0,0,0,2.114,1.835h1.353V54.64Z'
              transform='translate(-273.841 -36.48)'
              fill='#231f20'
            />
            <path
              id='Path_15'
              data-name='Path 15'
              d='M380.934,54.64l-.8,5.65a2.14,2.14,0,0,1-2.117,1.835H376.66V54.64Z'
              transform='translate(-333.93 -36.48)'
              fill='#231f20'
            />
            <path
              id='Path_16'
              data-name='Path 16'
              d='M318.042,32.14h-32.3a1.07,1.07,0,0,0-1.06.951l-.8,6.394a1.07,1.07,0,0,0,1.06,1.2h33.9a1.07,1.07,0,0,0,1.06-1.2l-.8-6.394A1.07,1.07,0,0,0,318.042,32.14Z'
              transform='translate(-271.982 -21.458)'
              fill='#231f20'
            />
            <path
              id='Path_17'
              data-name='Path 17'
              d='M321.133,66.4H299.265a2.134,2.134,0,0,1-2.12-1.871l-.834-6.674h27.774l-.834,6.674A2.137,2.137,0,0,1,321.133,66.4Z'
              transform='translate(-280.284 -38.63)'
              fill='#92826a'
            />
            <path
              id='Path_18'
              data-name='Path 18'
              d='M306.884,137.8l15.557-14.141-2.071-.475a3.2,3.2,0,0,0-2.958.838c-1.589,1.565-3.091,2.9-4.44,4.011a9.565,9.565,0,0,1-12.177,0c-1.329-1.11-2.845-2.443-4.43-3.988a3.194,3.194,0,0,0-2.975-.834l-2.04.479Z'
              transform='translate(-276.973 -82.186)'
              fill='#fff'
            />
            <path
              id='Path_19'
              data-name='Path 19'
              d='M321.68,139.37l5.454,4.959,5.454-4.959A9.565,9.565,0,0,1,321.68,139.37Z'
              transform='translate(-297.222 -93.05)'
              fill='#006472'
            />
          </g>

          <g id='Group_19' data-name='Group 19' transform='translate(0 0)'>
            <path
              id='Path_20'
              data-name='Path 20'
              d='M51.531,60.038,43.4,58.171a3.21,3.21,0,0,0-2.961.838h0a5.341,5.341,0,0,1-.9-2.965V52.364A19.014,19.014,0,0,0,43,46.824c2.154,1.432,4.018-3.107,4.018-5.088a2,2,0,0,0-2.227-2.23q.09-1.037.09-2.044c0-8.555-6.7-11.752-14.956-11.752s-14.969,3.2-14.969,11.752q0,1.017.093,2.061c-1.359-.16-2.23.685-2.23,2.213,0,2.064,1.855,6.906,3.928,4.876a19.207,19.207,0,0,0,3.55,5.753V56.05a5.341,5.341,0,0,1-.9,2.965h0a3.2,3.2,0,0,0-2.991-.834L8.229,60.1A10.682,10.682,0,0,0,0,70.494v9.7A3.207,3.207,0,0,0,3.2,83.4H56.619a3.207,3.207,0,0,0,3.2-3.207V70.434A10.678,10.678,0,0,0,51.531,60.038Z'
              transform='translate(0 -17.165)'
              fill='#fad7a5'
            />
            <path
              id='Path_21'
              data-name='Path 21'
              d='M82.194,115.93a5.348,5.348,0,0,1-1.894-4.081V109.29H61.07c1,2.895,7.834,9.206,14.019,11.812a24.956,24.956,0,0,0,7.106-5.171Z'
              transform='translate(-40.773 -72.967)'
              fill='#f0c891'
            />
            <path
              id='Path_22'
              data-name='Path 22'
              d='M40.437,124.038c-1.585,1.565-3.088,2.9-4.437,4.011a9.565,9.565,0,0,1-12.177,0c-1.346-1.11-2.848-2.443-4.43-3.988a3.2,3.2,0,0,0-2.991-.834l-8.179,1.924A10.682,10.682,0,0,0,0,135.537v9.7a3.207,3.207,0,0,0,3.2,3.207H56.619a3.207,3.207,0,0,0,3.2-3.207v-9.758a10.678,10.678,0,0,0-8.292-10.4L43.4,123.213A3.21,3.21,0,0,0,40.437,124.038Z'
              transform='translate(0 -82.208)'
              fill='#006b77'
            />
            <path
              id='Path_23'
              data-name='Path 23'
              d='M52.894,170.889a6.145,6.145,0,0,1-2.961-.741l-3.719-2.057a6.185,6.185,0,0,0-2.965-.741H16.574a6.186,6.186,0,0,0-2.948.731l-3.719,2.057a6.145,6.145,0,0,1-2.978.751H0v4.427a2.97,2.97,0,0,0,3.2,2.659H56.619a2.97,2.97,0,0,0,3.2-2.659v-4.427Z'
              transform='translate(0 -111.731)'
              fill='#006b77'
            />
            <path
              id='Path_24'
              data-name='Path 24'
              d='M70.529,39.506q.09-1.037.09-2.044c0-8.555-6.7-11.752-14.956-11.752s-14.956,3.2-14.956,11.752q0,1.017.093,2.061c-1.359-.16-2.23.685-2.23,2.213,0,2.064,1.855,6.906,3.928,4.876,2.25,5.534,6.62,10.08,13.164,10.08,6.461,0,10.8-4.43,13.075-9.867,2.154,1.432,4.018-3.107,4.018-5.088A2,2,0,0,0,70.529,39.506Z'
              transform='translate(-25.751 -17.165)'
              fill='#ffe1b4'
            />
            <path
              id='Path_25'
              data-name='Path 25'
              d='M57.813,79.223c-10.931,0-16.023-12.749-16.023-21.363h2.134c0,7.754,4.417,19.226,13.889,19.226S71.7,65.614,71.7,57.86h2.134C73.835,66.475,68.744,79.223,57.813,79.223Z'
              transform='translate(-27.901 -38.63)'
              fill='#231f20'
            />
            <path
              id='Path_26'
              data-name='Path 26'
              d='M65.273,1.329a.97.97,0,0,0-.5-.14H63.342a1.017,1.017,0,0,0-.754.349h0a1,1,0,0,1-1.512,0L60.321.695A2.027,2.027,0,0,0,58.812,0H56.306A2.027,2.027,0,0,0,54.8.695l-.754.841a1,1,0,0,1-1.512,0h0a1.017,1.017,0,0,0-.754-.349H50.347a.97.97,0,0,0-.5.143c-1.177.7-6.418,4.1-8.435,9.352h32.3C71.69,5.427,66.449,2.034,65.273,1.329Z'
              transform='translate(-27.647)'
              fill='#231f20'
            />
            <path
              id='Path_27'
              data-name='Path 27'
              d='M38.57,54.64l.808,5.65a2.134,2.134,0,0,0,2.114,1.835h1.353V54.64Z'
              transform='translate(-25.751 -36.48)'
              fill='#231f20'
            />
            <path
              id='Path_28'
              data-name='Path 28'
              d='M132.844,54.64l-.808,5.65a2.134,2.134,0,0,1-2.114,1.835H128.57V54.64Z'
              transform='translate(-85.84 -36.48)'
              fill='#231f20'
            />
            <path
              id='Path_29'
              data-name='Path 29'
              d='M69.952,32.14h-32.3a1.07,1.07,0,0,0-1.06.951l-.8,6.394a1.07,1.07,0,0,0,1.06,1.2h33.9a1.07,1.07,0,0,0,1.06-1.2l-.8-6.394A1.07,1.07,0,0,0,69.952,32.14Z'
              transform='translate(-23.892 -21.458)'
              fill='#231f20'
            />
            <path
              id='Path_30'
              data-name='Path 30'
              d='M73.033,66.4H51.165a2.134,2.134,0,0,1-2.117-1.871L48.21,57.86H75.988l-.834,6.674A2.137,2.137,0,0,1,73.033,66.4Z'
              transform='translate(-32.187 -38.63)'
              fill='#ffe1b4'
            />
            <path
              id='Path_31'
              data-name='Path 31'
              d='M58.761,137.8l15.554-14.141-2.067-.475a3.211,3.211,0,0,0-2.961.838c-1.585,1.565-3.088,2.9-4.437,4.011a9.565,9.565,0,0,1-12.177,0c-1.346-1.11-2.848-2.443-4.43-3.988a3.2,3.2,0,0,0-2.991-.834l-2.041.479Z'
              transform='translate(-28.849 -82.186)'
              fill='#fff'
            />
            <path
              id='Path_32'
              data-name='Path 32'
              d='M73.59,139.37l5.454,4.959L84.5,139.37A9.565,9.565,0,0,1,73.59,139.37Z'
              transform='translate(-49.132 -93.05)'
              fill='#006472'
            />
          </g>
          <g
            id='Group_20'
            data-name='Group 20'
            transform='translate(38.174 6.411)'
          >
            <path
              id='Path_33'
              data-name='Path 33'
              d='M172.127,86.011l-9.017-2.074a3.566,3.566,0,0,0-3.29.931l-.017.017a5.932,5.932,0,0,1-1-3.294V77.512a21.211,21.211,0,0,0,3.845-6.158c2.393,1.592,4.463-3.453,4.463-5.65a2.227,2.227,0,0,0-2.476-2.476c.07-.768.1-1.529.1-2.273,0-9.505-7.441-13.055-16.618-13.055s-16.617,3.55-16.617,13.055c0,.751.033,1.519.1,2.293-1.512-.179-2.479.758-2.479,2.456,0,2.293,2.061,7.674,4.367,5.417a21.38,21.38,0,0,0,3.925,6.391V81.6a5.933,5.933,0,0,1-1,3.294h0a3.559,3.559,0,0,0-3.307-.927l-9.09,2.14a11.868,11.868,0,0,0-9.15,11.552v10.761a3.556,3.556,0,0,0,3.559,3.559h59.341a3.559,3.559,0,0,0,3.563-3.559V97.58a11.869,11.869,0,0,0-9.217-11.569Z'
              transform='translate(-114.873 -38.391)'
              fill='#d7bb91'
            />
            <path
              id='Path_34'
              data-name='Path 34'
              d='M206.184,148.091a5.936,5.936,0,0,1-2.107-4.537V140.71H182.71c1.12,3.217,8.7,10.233,15.577,13.128a27.782,27.782,0,0,0,7.9-5.746Z'
              transform='translate(-160.16 -100.356)'
              fill='#c5a578'
            />
            <path
              id='Path_35'
              data-name='Path 35'
              d='M159.79,157.075c-1.761,1.738-3.433,3.221-4.932,4.457a10.635,10.635,0,0,1-13.53,0c-1.5-1.233-3.164-2.712-4.922-4.447a3.56,3.56,0,0,0-3.307-.927l-9.09,2.14a11.868,11.868,0,0,0-9.15,11.553v10.778a3.556,3.556,0,0,0,3.559,3.559h59.348a3.559,3.559,0,0,0,3.563-3.559V169.787a11.868,11.868,0,0,0-9.217-11.569l-9.016-2.074A3.566,3.566,0,0,0,159.79,157.075Z'
              transform='translate(-114.86 -110.599)'
              fill='#006b77'
            />
            <path
              id='Path_36'
              data-name='Path 36'
              d='M173.629,209.095a6.836,6.836,0,0,1-3.29-.824l-4.131-2.283a6.85,6.85,0,0,0-3.294-.828H133.272a6.836,6.836,0,0,0-3.29.828l-4.131,2.283a6.847,6.847,0,0,1-3.3.824H114.86v4.919c0,1.632,1.592,2.951,3.559,2.951h59.348c1.967,0,3.563-1.329,3.563-2.951v-4.919Z'
              transform='translate(-114.86 -143.386)'
              fill='#006b77'
            />
            <path
              id='Path_37'
              data-name='Path 37'
              d='M193.218,63.208c.07-.768.1-1.529.1-2.273,0-9.505-7.441-13.055-16.618-13.055s-16.618,3.549-16.618,13.055c0,.751.033,1.519.1,2.293-1.512-.179-2.479.758-2.479,2.456,0,2.293,2.061,7.674,4.367,5.417,2.5,6.148,7.355,11.2,14.623,11.2,7.175,0,12-4.922,14.527-10.968,2.393,1.592,4.463-3.453,4.463-5.65A2.217,2.217,0,0,0,193.218,63.208Z'
              transform='translate(-143.469 -38.378)'
              fill='#d1ba96'
            />
            <path
              id='Path_38'
              data-name='Path 38'
              d='M179.094,107.31c-12.147,0-17.8-14.165-17.8-23.74h2.373c0,8.618,4.906,21.367,15.431,21.367s15.431-12.749,15.431-21.367H196.9C196.9,93.145,191.238,107.31,179.094,107.31Z'
              transform='translate(-145.859 -62.207)'
              fill='#231f20'
            />
            <path
              id='Path_39'
              data-name='Path 39'
              d='M187.425,20.762a1.05,1.05,0,0,0-.558-.156h-1.589a1.127,1.127,0,0,0-.838.389h0a1.1,1.1,0,0,1-1.662,0l-.841-.934a2.253,2.253,0,0,0-1.708-.771h-2.782a2.26,2.26,0,0,0-1.662.771l-.837.934a1.1,1.1,0,0,1-1.662,0h0a1.127,1.127,0,0,0-.841-.389h-1.589a1.04,1.04,0,0,0-.555.156c-1.309.788-7.132,4.56-9.372,10.4h35.894C194.554,25.322,188.731,21.55,187.425,20.762Z'
              transform='translate(-145.619 -19.29)'
              fill='#231f20'
            />
            <path
              id='Path_40'
              data-name='Path 40'
              d='M157.71,80l.9,6.271a2.373,2.373,0,0,0,2.35,2.037h1.5V80Z'
              transform='translate(-143.469 -59.823)'
              fill='#231f20'
            />
            <path
              id='Path_41'
              data-name='Path 41'
              d='M262.459,80l-.9,6.271a2.37,2.37,0,0,1-2.35,2.037h-1.5V80Z'
              transform='translate(-210.234 -59.823)'
              fill='#231f20'
            />
            <path
              id='Path_42'
              data-name='Path 42'
              d='M192.568,55H156.674a1.19,1.19,0,0,0-1.18,1.04l-.891,7.122a1.19,1.19,0,0,0,1.18,1.329h37.675a1.186,1.186,0,0,0,1.177-1.329l-.891-7.122A1.183,1.183,0,0,0,192.568,55Z'
              transform='translate(-141.389 -43.132)'
              fill='#231f20'
            />
            <path
              id='Path_43'
              data-name='Path 43'
              d='M196.008,93.065H171.714a2.37,2.37,0,0,1-2.356-2.077l-.927-7.418h30.862l-.927,7.418A2.376,2.376,0,0,1,196.008,93.065Z'
              transform='translate(-150.626 -62.207)'
              fill='#d1ba96'
            />
            <path
              id='Path_44'
              data-name='Path 44'
              d='M180.182,172.389l17.282-15.71-2.28-.528a3.566,3.566,0,0,0-3.29.931c-1.761,1.738-3.433,3.221-4.932,4.457a10.635,10.635,0,0,1-13.53,0c-1.5-1.233-3.164-2.712-4.922-4.447a3.559,3.559,0,0,0-3.307-.927l-2.283.542Z'
              transform='translate(-146.947 -110.605)'
              fill='#fff'
            />
            <path
              id='Path_45'
              data-name='Path 45'
              d='M196.62,174.14l6.062,5.507,6.059-5.507A10.635,10.635,0,0,1,196.62,174.14Z'
              transform='translate(-169.447 -122.676)'
              fill='#006472'
            />
          </g>
        </g>
      </g>
      <g
        id='Mask_Group_22'
        data-name='Mask Group 22'
        transform='translate(-407.887 -571.119)'
        clipPath='url(#clipPath)'
      >
        <g id='shape'>
          <g id='Group_76' data-name='Group 76' transform='translate(0)'>
            <g
              id='Layer_2'
              data-name='Layer 2'
              transform='translate(474.614 674.86)'
            >
              <g id='logos'>
                <path
                  id='Path_4'
                  data-name='Path 4'
                  d='M14.8,409.289a12.685,12.685,0,0,1-7.425-2.678c-.243-.175-.482-.359-.955-.752-.166-.136-.33-.277-.72-.635C1.923,401.817-1.023,394.958.338,386.8L11.62,325.419c1.354-8.917,7.311-15.367,14.216-15.389H322.969c6.77.238,12.659,6.6,14.1,15.389L348.219,386.8c2.076,12.856-6.476,22.553-14.419,22.488H14.8'
                  transform='translate(0 -310.03)'
                  fill='#ea7200'
                  stroke='#000'
                  strokeWidth='2'
                />
              </g>
            </g>
            <path
              id='Path_5'
              data-name='Path 5'
              d='M641.412,671.022A41.62,41.62,0,0,1,623.44,666.1c-7.3-3.887-17.39-11.316-29.063-25.053l-23.2-34.5L532.3,546.983,506.509,508.37,469.751,449,441.974,404.74l-39.849-60.3-47.566-73.589-45.13-69.563-41.587-64.932L214.25,53.785,183.968,8.03s-11.34-22.736-35.144-5.27c-12.039,8.573-6.323,21.842-3.938,26.784,4.062,8.16,48.74,78.481,48.74,78.481l29.6,45.257,30.35,48.244,33.08,51.015,30.553,47.487,23.806,38.418,40.233,63.633,58.669,88.74,32.742,51.123,30.372,47.616,41.564,64.2,30.372,46.75,30.593,44.223,6.337,8.623,8.1,11.024'
              transform='translate(-141.425 3.838)'
              fill='#ea7200'
              stroke='#000'
              strokeWidth='2'
            />
          </g>
        </g>
        <text
          id='Text_2'
          data-name='Text '
          transform='translate(635.887 694.119)'
          fill='#fff'
          stroke='#000'
          strokeWidth='2'
          fontSize='48'
          fontWeight='700'
        >
          <tspan x='8.048' y='47'>
            Scout
          </tspan>
        </text>
      </g>
      <g id='binoculars-svgrepo-com' transform='translate(100 115)'>
        <g id='Group_37' data-name='Group 37' transform='translate(0 2.697)'>
          <path
            id='Path_109'
            data-name='Path 109'
            d='M10.637,17.441c-1.247-.311-2.59-.667-2.594-.668-.549-.156-2.206.761-3.489,2.457L2.26,18.658c-.349-.1-1.71.783-2.129,2.257S.4,23.9.742,24l2.179.695c.146,2.249,1.1,4.063,1.672,4.226.005,0,1,.278,2.416.733a11.444,11.444,0,0,1,.256-6.817A10.983,10.983,0,0,1,10.637,17.441Z'
            transform='translate(0 13.796)'
            fill='#006b77'
          />
          <path
            id='Path_110'
            data-name='Path 110'
            d='M96.533,25.262c-.062-.02-32.268-7.9-51.053-12.675-3.832,2.811-6.7,6.88-8.589,13.691a41.472,41.472,0,0,0-1.626,10.575c-2.47-.614-4.913-1.226-7.272-1.82a39.112,39.112,0,0,1,1.651-10.974,27.861,27.861,0,0,1,8.352-13.628C34.9,9.645,31.583,8.746,31.571,8.74c-1.356-.4-5.461,1.923-8.634,6.207C19.6,14.1,17.264,13.507,17.254,13.5c-.861-.25-4.233,1.979-5.265,5.7S12.64,26.744,13.5,27l5.4,1.759a24.235,24.235,0,0,0,.681,4.332c-3.743,2.808-6.547,6.854-8.409,13.557-2.5,8.967-1.735,15.458-.324,17.573,18.7,6.1,50.172,16.373,50.234,16.386,3.4.991,8.329-6.043,11.018-15.7a46.258,46.258,0,0,0,1.6-8.94c7.661,2.5,13.075,4.266,13.1,4.271,3.4.988,8.332-6.048,11.013-15.7S99.931,26.248,96.533,25.262Zm-29.065,38.3c-2.04,7.319-5.725,12.652-8.243,11.925s-2.906-7.249-.871-14.566S64.079,48.267,66.594,49,69.5,56.249,67.468,63.561ZM93.189,43.2c-2.037,7.317-5.728,12.652-8.24,11.922s-2.906-7.249-.871-14.563S89.8,27.9,92.315,28.632,95.226,35.875,93.189,43.2Z'
            transform='translate(0 -8.697)'
            fill='#006b77'
          />
        </g>
      </g>
    </svg>
  );
};
